<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 " data-cmp="ProfileMain">
        <AppHeader></AppHeader>
        <section class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12 d-flex flex-row">
                    <!-- sidebar -->
                    <div class="sidebar position-fixed">
                        <Sidebar></Sidebar>
                    </div>

                    <!-- content area -->
                    <div class="content bg-canvas d-flex flex-column flex-fill">
                        <!-- sub navigation -->
                        <div class="bg-white border-bottom border-start d-flex flex-row navbar-wrapper px-3 py-2.5 --shadow-sm">
                            <div class="align-items-center d-flex">
                                <a @click.prevent="$router.back()" class="btn btn-primary btn-sm font-size-16 px-1 rounded-pill">
                                    <span class="material-icons pull-left">
                                    chevron_left
                                    </span>
                                </a>
                            </div>

                            <div>
                                <nav class="ps-3" style="--bs-breadcrumb-divider: '›';" aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                                        <li class="breadcrumb-item">
                                            <router-link class="breadcrumb-item text-decoration-none" :to="$root.links.DASHBOARD">
                                                <span class="">Dashboard</span>
                                            </router-link>
                                        </li>

                                        <li class="breadcrumb-item">
                                            <router-link :to="$root.links.MY_OPPORTUNITIES"
                                                class="text-decoration-none"
                                                >
                                                <span>Opportunities</span>
                                            </router-link>
                                            
                                        </li>

                                        <li class="breadcrumb-item">
                                            Library
                                        </li>

                                        <li class="breadcrumb-item">
                                            Opportunities
                                        </li>

                                        <li class="breadcrumb-item">
                                            {{ kindLabel }}
                                        </li>

                                        <li v-if="$route.params?.strategy" class="breadcrumb-item">
                                            {{ strategyLabel }}
                                        </li>
                                    </ol>
                                </nav>
                            </div>


                            <div class="ms-auto">
                                <router-link to="/library/opportunities/all"
                                    class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-2 pe-3"
                                    :class="[ /\/library\/opportunities\//.test($route.fullPath) ? 'disabled text-muted' : '' ]"
                                    >
                                    <span class="end-0 material-icons-outlined pull-left">attach_money</span>
                                    <span>Opportunities</span>
                                </router-link>

                                <router-link to="/library/strategies/sales-pages"
                                    class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-2 pe-3"
                                    :class="[ /\/library\/strategies\//.test($route.fullPath) ? 'disabled text-muted' : '' ]"
                                    >
                                    <span class="end-0 material-icons-outlined pull-left me-1">gamepad</span>
                                    <span>Strategies</span>
                                </router-link>

                                <ButtonRecentOpportunities class="d-inline"></ButtonRecentOpportunities>
                            </div>
                        </div>


                        <!-- info area -->

                        <div class="overflow-scroll pb-5 pt-5 px-4 px-lg-4 shadow-inset-md">
                            <div class="content-wrapper mx-auto ">
                                <div class="position-relative">
                                    <div class="kind-landing-pages d-flex flex-wrap justify-content-center align-content-stretch position-relative">
                                        <OpportunityCard v-for="(opportunity, index) in library" :key="index" 
                                            :action="action" :opportunity="opportunity"></OpportunityCard>
                                    </div>
                                </div>
                            </div>
                            <AppFooter class="mt-5"></AppFooter>
                        </div>


                        <!-- <section class="overflow-scroll w-100 pt-5">
                            <div class="container-fluid px-5">
                                <div class="row">
                                    <div v-for="(opportunity, index) in library" :key="index" class="col-md-6 col-xl-4 mb-5">
                                        <OpportunityCard :action="action" :opportunity="opportunity"></OpportunityCard>
                                    </div>
                                </div>
                            </div>
                            <AppFooter class="mt-5"></AppFooter>
                        </section> -->
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>
<script>

import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import Sidebar   from "./Sidebar.vue";
import OpportunityCard from "./OpportunityCard";
import ButtonRecentOpportunities from "@/components/ButtonRecentOpportunities.vue";

export default {
    components: {
        AppHeader, 
        AppFooter,
        Sidebar,
        OpportunityCard,
        ButtonRecentOpportunities
    },

    data() {
        return {
            tabs: [
                {
                    title: 'All',
                    url: 'all',
                    trigger: null
                },
                {
                    title: 'B2C',
                    url: 'b2c',
                    trigger: null
                },
                {
                    title: 'B2B',
                    url: 'b2b',
                    trigger: null
                }
            ],
            opportunityTemplates: {},
            currentTemplate: null,
        };
    },
    
    computed: {
        library() {
            let templates = this.$store.getters.opportunityTemplates;
            return templates;
        },

        kind() {
            let cmp = this.$route.params?.kind;
            switch (cmp) {
                case undefined:
                    cmp = '(?)';
                    break;
            
                default:
                    cmp = 'Kind' + cmp.split('-').map((part)=>{
                        part = part[0].toUpperCase() + part.substr(1);
                        return part;
                    }).join('');
                    break;
            }

            return cmp;
        },


        kindLabel() {
            let label = this.$route.params?.kind;
            switch (label) {
                case undefined:
                    label = '(?)';
                    break;
            
                default:
                    label = label.replace(/-/, ' ').trim();
                    label = label[0].toUpperCase() + label.substr(1);
                    break;
            }

            return label;
        },

        startegyLabel() {
            let label = this.$route.params?.strategy;
            switch (label) {
                case undefined:
                    label = '(?)';
                    break;
            
                default:
                    label = label.replace(/-/, ' ').trim();
                    label = label[0].toUpperCase() + label.substr(1);
                    break;
            }

            return label;
        }, 
    },

    methods: {
        async action(name, data) {
            var scope = this;
            var tmpl = {};

            if(this.opportunityTemplates?.[data?.name]) {
                tmpl = this.opportunityTemplates[data.name];
            } else {
                if(data?.ID) {
                    this.$store.commit('SET_LOADING_STATE', true);
                    tmpl = await this.$store.getters.wp.getOpportunityTemplate(data.ID);
                    this.opportunityTemplates[data.name] = tmpl;
                    this.$store.commit('SET_LOADING_STATE', false);
                }
            }

            scope.currentTemplate = tmpl;
            // console.log('CURRENT: ' + scope.currentTemplate.title);

            switch (name) {
                case 'info':
                    var modal = this.$root.modal({
                        title: 'Create from template',
                        context: tmpl, 
                        okay: {
                            label: 'USE TEMPLATE', 
                            callback(content) {
                                let template = content.opportiunityTmpl;

                                setTimeout(() => {
                                    // console.log('USE TEMPLATE ' + scope.currentTemplate.title);
                                    // console.log(this)
                                    // console.log(scope)
                                    // console.log(scope.currentTemplate)
                                    // console.log(scope.currentTemplate.title)
                                    scope.$root.notify({
                                        title: 'What would you like to name this offer?',
                                        type: 'prompt',
                                        value: template.title,
                                        callback(okay, value) {
                                            if(okay) {
                                                let request = {...data};
                                                    request.title = value;
                                                delete request.name;
                                                scope.createOpportunityFromLibrary(request);
                                            }
                                        }
                                    });
                                }, 330);
                            }
                        }
                    }, 'OpportunityTmplInfo');

                    /* modal needs to be fully mounted before calling methods from its content */
                    setTimeout(() => {   
                        modal.content().setup(tmpl);
                    }, 0);

                    break;

                default:
                    break;
            }
        }, 

        createOpportunityFromLibrary(data) {
            var scope = this;
            let request = {
                blueprint: data.ID,
                post_title: data?.title
            };

            // console.log('createOpportunityFromLibrary', request);
            
            // scope;
            this.$store.getters.wp.createOpportunityFromLibrary(request).then( (opportunity) => {
                scope.$root.reloadOpportunities();
                this.$root.notify({
                    title: 'Your new offer has been created',
                    type: 'confirm',
                    message: 'Would you like to open this offer?',
                    callback(okay) {
                        okay && scope.$root.action('openOpportunity', opportunity);
                    }
                })
            });
        }
    },

    async mounted() {
        /** force loading opportunity templates */
        if(this.$store.getters.opportunityTemplates?.length === 0) {
            await this.$store.dispatch('fetchOpportunityTemplates');
        }

        window.library = this;
    }
}
</script>

<style lang="css" scoped>

.sidebar {
   width: 320px; 
}
.content {
   margin-left: 320px; 
   height: calc(100vh - 86px);
}
.navbar-wrapper {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}
.rounded-5 {
    border-radius: .5rem!important;
}

.disabled {
    color: var(--secondary) !important;
    border-color: var(--bg-secondary) !important;
    background-color: var(--bg-secondary)!important;
}

.shadow-inset-md {
    box-shadow: inset 4px -4px 8px -4px rgb(0 0 0 / 10%);
}
</style>