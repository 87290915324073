<template lang="">
    <div class="appc-widget card-opportunity bg-white border-0 card shadow-sm border-radius-8 mx-2 mb-5 --col-6 --col-lg-4 --col-xl-3">
        <a href="#" @click.prevent="action('info', opportunity)" class="card-body p-0 position-relative overflow-hidden flex-grow-0 border-top-left-radius-inherit border-top-right-radius-inherit">
            <figure class="mb-0 ratio ratio-16x9 border-top-left-radius-inherit border-top-right-radius-inherit">
                <img :src="opportunity?.thumbnail || 'https://dummyimage.com/600x375/cdcee0/ffffff?text=&nbsp;'" :alt="opportunity?.title"
                    class="border-top-left-radius-inherit border-top-right-radius-inherit"
                    >
            </figure>
            <div class="h-100 start-0 top-0 w-100 ">
                <div class="img-cover rounded-circle bg-primary position-absolute start-50 top-50 translate-middle"></div>
                
                <div class="icon-add position-absolute top-50 start-50 lh-1 translate-middle btn btn-default text-white d-inline-block mx-auto">
                    <span class="material-icons-outlined fs-1">
                    add
                    </span>
                </div>
            </div>
        </a>
        
        <div class="position-relative card-footer border-0 mb-2 bg-white pt-3 d-flex flex-column align-items-start">
            <h3 class="fs-5 mb-0 pt-0 w-100">
                <a class="text-reset text-decoration-none hover:text-primary stretched-link" href="#" @click.prevent="action('info', opportunity)">{{opportunity?.title}}</a>
            </h3>
            <span class="text-black-50 flex-fill">{{opportunity?.excerpt || 'Nibh ipsum consequat nisl vel'}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OpportunityCard',

    props: {
        opportunity: {
            type: Object, 
            default: function() {
                return {}
            }
        },
        action: {
            type: Function, 
            default: function() {}
        }
    }
    
}
</script>
<style lang="css">

.card-opportunity {
    --cover-transition-duration: 0.3s;
    --cover-transform: scale3d(13,13,1) translate(-50%,-50%);
    --img-transition-duration: 0.5s;
    --img-transform: scale3d(1.05, 1.05, 1);
}

@media screen and (max-width: 1439px) {
    .card-opportunity {
        width: 280px;
    }
}
@media screen and (min-width: 1440px) {
    .card-opportunity {
        width: 300px;
    }
}
@media screen and (min-width: 1600px) {
    .card-opportunity {
        width: 360px;
    }
}

.card-opportunity .hover\:btn-add:hover {
    color: orange !important;
}

.border-top-left-radius-inherit { border-top-left-radius: inherit }
.border-top-right-radius-inherit { border-top-right-radius: inherit }
.border-bottom-left-radius-inherit { border-bottom-left-radius: inherit }
.border-bottom-right-radius-inherit { border-bottom-left-radius: inherit }


.card-opportunity .card .btn-more-info {
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
}

.card-opportunity .card:hover .btn-more-info {
    opacity: 1;
}

.card-opportunity img {
    object-fit: cover;
    object-position: center;
    transition: all ease-in-out;
    transition-duration: var(--img-transition-duration);
}

.card-opportunity .img-cover {
    width: 50px;
    height: 50px;
    opacity: 0;
    transition: all ease-in-out;
    transition-duration: var(--cover-transition-duration);
    transform-origin: left top;
    transform: scale3d(1, 1, 1);
}

.card-opportunity .card-body:hover .img-cover {
    transform: var(--cover-transform) !important;
    opacity: 0.75;
}

.card-opportunity .card-body:hover img {
    transform: var(--img-transform);
}

.card-opportunity .card-body .icon-add {
    transition-delay: 0.5s;
    transition: all 0.25s;
    opacity: 0;
}

.card-opportunity .card-body:hover .icon-add {
    opacity: 1;
}

</style>