<template lang="">
    <div id="sidebar">
        <div class="p-2 pt-4">
            <div class="" >
                <h3 class="fs-4 fw-500 mb-0 pb-1 ps-3">
                    <!-- <span class="material-icons-outlined pull-left me-1">
                    attach_money
                    </span> -->
                    Opportunities</h3>
                <small class="d-block ps-3 pe-2 mb-3 text-muted">Indentify new business opportunities.</small>

                <ul class="list-group list-group-flush">
                    <li v-for="(item, index) in options" :key="index"
                        class="list-group-item position-relative rounded-3 border-0 mt-0 user-select-none"
                        :class="[item.name == $route?.params?.kind ? 'active': '', item.disabled? 'disabled pointer-events-none' : '']">
                        <router-link  :to="`/library/opportunities/${item.name}`" class="stretched-link text-reset text-decoration-none fw-500 font-size-18" @click.prevent="">
                            <IconButton label="" :icon="item.icon" class=""></IconButton>
                            <span class="ms-2 item-title">
                                {{item.title}}      
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import IconButton from "@/components/IconButton.vue";


export default {
    components: {
        IconButton
    },
    
    data() {
        return {
            options: [
                {name: 'all', title:'All', icon: 'done_all', disabled: false},
                {name: 'b2c', title:'B2C', icon: 'group', disabled: true},
                {name: 'b2b', title:'B2B', icon: 'business', disabled: true},
            ],
            current: {
                tab: null,
                tabURL: '',
                form: null,
                formName: ''
            },
        }
    },
    computed: {

        // tabs() {
        //     return this.$store.getters.tabs;
        // }, 
    }
}
</script>
<style scoped>
    
.nav-item {
    width: 33%;
}

.list-group-item {
    cursor: pointer;
    margin-bottom: 2px;
    transition: all 0.2s;
}
.list-group-item.active {
    background-color: var(--primary-light) !important;
}
.list-group-item:not(.active):hover {
    background-color: var(--primary-lighter) !important;
}
.list-group-item.active a {
    color: var(--text-main) !important;
}
.list-group-item.active .btn-icon {
    background-color: var(--primary) !important;
    color: #ffffff !important;
}

/* completed */
.list-group-item.active.completed {
    background-color: hsl(134 61% 41%/.2)!important;
    color: var(--success)!important;
}
.list-group-item.completed:hover {
    background-color: hsl(134 61% 41%/.3)!important;
}
.list-group-item.completed > a {
    color: var(--bs-success)!important;
}
.list-group-item.completed .btn-icon {
    background-color: var(--success)!important;
    color: var(--bs-white)!important;
}

/* disabled */
.list-group-item.disabled {
    opacity: 0.5;
}


/* .list-group-item.disabled .item-title:after {
    content: 'PRO';
    font-size: 11px;
    border: solid 1px var(--secondary);
    padding: 0px 3px;
    border-radius: 3px;
    display: inline-block;
    margin-left: 5px;
    height: 16px;
    transform: translateY(-3px);
} */
</style>